import React from "react"
import {
  Link, Typography, Grid, Divider, Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

import Seo from "components/Seo";

import Breadcrumbs from "../components/Breadcrumbs";

const BoldTypography = styled(Typography)(() => ({
 fontWeight: 'bold',
 display: 'inline-block'
}));

const LabelTypography = styled(Typography)(({theme}) => ({
  fontWeight: 'bold',
  fontSize: theme.typography.pxToRem(14),
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1),
}));

type TypographyProps = {
  component: string
}

const WrapTypography = styled(Typography)<TypographyProps>(({theme}) => ({
  marginBottom: theme.spacing(1),
  fontSize: theme.typography.pxToRem(16),
}));

const ParagraphTypography = styled(Typography)<TypographyProps>(({theme}) => ({
  marginBottom: theme.spacing(4),
  fontSize: theme.typography.pxToRem(16),
}));

const About = () => {
  return (
    <>
      <Seo title={`Despre noi`}/>
      <Breadcrumbs
        dataBreadcrumb={
          [
            {
              id: 'about',
              name: 'Despre noi',
            },
          ]}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <LabelTypography>
            Scurt istoric:
          </LabelTypography>
          <ParagraphTypography gutterBottom component="div">
            <BoldTypography>Adventurer Sports SRL</BoldTypography> a fost  înfiinţată la inceputul
            anului 2004, iar de atunci si pană azi si-a continuat activitatea pe piaţa din Romania, este un importator
            şi distribuitor naţional de rucsaci si genti, de marca
            "<BoldTypography>ADVENTURER SPORTS</BoldTypography>".
          </ParagraphTypography>
          <ParagraphTypography gutterBottom component="div">
            Vă oferim o gamă largă de produse de marochinărie: rucsaci de munte, ghiozdane, genţi de voiaj, genţi de umăr, genţi de damă, borsete, sac echipament si pormonee.
          </ParagraphTypography>
          <ParagraphTypography gutterBottom component="div">
            Calitatea produselor comercializate este un mare atuu al firmei noastre mai ales ca si preţurile sunt mult inferioare fată de alte produse similare de pe piată.
          </ParagraphTypography>
          <Box sx={{m:2}}>
            <WrapTypography component="div">
              <LabelTypography>
                Companie Comercială:
              </LabelTypography>
              S.C. ADVENTURER SPORTS SRL.
            </WrapTypography>
            <WrapTypography  component="div">
              <LabelTypography>Adresa:</LabelTypography>
               Str: Avram Iancu Nr: 17, Mun: Săcele, Jud Brasov, Cod Postal: 505600
            </WrapTypography>
            <WrapTypography component="div">
              <LabelTypography>Email:</LabelTypography>
              <Link
                href="mailto:adventurersports@hotmail.com"
                role="link"
                target="_blank">
                adventurersports@hotmail.com
              </Link>
            </WrapTypography>
            <WrapTypography component="div">
              <LabelTypography>Telefon:</LabelTypography>
              0726 030 430
            </WrapTypography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>
        <Grid item xs={12}>
          <div className="mapouter">
            <div>
              <iframe
                title="adventurersports.ro"
                width="100%"
                height="320"
               //  id={classes.gmapCanvasId}
                src="https://maps.google.com/maps?q=adventurersports.ro&t=&z=13&ie=UTF8&iwloc=&output=embed"
                frameBorder="0"
                scrolling="no"
                marginHeight={0}
                marginWidth={0}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default About
