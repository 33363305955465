import React from 'react';
import { Link } from 'gatsby'

import { ChevronRight } from '@mui/icons-material'
import {Typography, Button, Box, styled } from '@mui/material';
import { BreadCrumb } from 'types';


const BoxFlex =  styled(Box)`
  display: flex;
  flex: auto;
  flex-wrap: wrap;
  align-items: center;
  min-height: 38px;
  margin-bottom: ${props => props.theme.spacing(3)};
  // ${props => props.theme.breakpoints.down("sm")} {
  //  display: none;
  // }
  a {
    text-transform: none;
  }
`

const Separator =  styled(Typography)`
  color: ${props => props.theme.palette.grey[300]};
  height: ${props => props.theme.spacing(3)};
`

const BreadcrumbsItem: React.FC<{ item: BreadCrumb, itemRenderer: any, separator: any, lastItem: boolean}> =
  ({ item, itemRenderer, separator, lastItem}) => {
  const { component, ...itemData } = item;

  if (component) {
    const ItemComponent = component;
    return (
      <ItemComponent
        itemRenderer={itemRenderer}
        item={itemData}
      />
    );
  }

  if (itemRenderer) {
    const params: BreadCrumb = { ...item };
    return itemRenderer(params, !lastItem ? separator : null);
  }

  if (item?.disabled) {
    return (
      <>
        <Typography
          component="h1"
          noWrap
          color="textSecondary"
          variant="body2"
        >
          {item?.name}
        </Typography>
        <Separator>{separator}</Separator>
      </>
    );
  }
  if (item?.to) {
    return (
      <>
        <Button
          component={Link}
          to={item.to}
          variant="text"
        >
          {item.name}
        </Button>
        <Separator>{separator}</Separator>
      </>
    );
  }

  return (
    <Typography
      variant="body2"
      noWrap
      color="textSecondary"
      component="h1"
    >
      {item?.name}
    </Typography>
  );
};

const Breadcrumbs:  React.FC<{ dataBreadcrumb:BreadCrumb[], className?:string, itemRenderer?: any,  separator?:any}> = ({
  dataBreadcrumb,
  children,
  className,
  separator,
  itemRenderer
}) => {
  return (
    <BoxFlex className={className}>
      {children}
      {dataBreadcrumb
        .slice(0)
        .reverse()
        .map((item, index) => (
          <BreadcrumbsItem
            key={item?.id || item?.name || item?.to}
            item={item}
            lastItem={Boolean(index === dataBreadcrumb.length - 1)}
            separator={separator}
            itemRenderer={itemRenderer}
          />
        ))}
    </BoxFlex>
  );
}

Breadcrumbs.defaultProps = {
  separator: <ChevronRight width={14} height={14} color="inherit"/>,
  dataBreadcrumb: [],
};

export default Breadcrumbs;
